import React from 'react';

type CloseButtonProps = {
    icon?: React.ReactNode;
    id?: string;
    className?: string;
    onButtonClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    toggle?: boolean;
    active?: boolean;
    title: string;
};

export default function CloseButton({ icon = '\u00A0', id = '', className = '', onButtonClick, toggle = false, active = false, title = '' }: CloseButtonProps) {
    if (toggle) {
        return (
            <div className={`close-button close-button__toggle ${className}`} title={title} id={id} onClick={onButtonClick}>
                <input type='checkbox' className='close-button__checkbox' id={id + 'Checkbox'} checked={active} readOnly />
                <label htmlFor={id + 'Checkbox'} className='close-button__button' >
                    <span className="close-button__icon">
                        {icon}
                    </span>
                </label>
            </div>
        )
    }

    return (
        <div className={`close-button ${className}`} title={title} onClick={onButtonClick}>
            <span className="close-button__icon">
                {icon}
            </span>
        </div>
    )
}