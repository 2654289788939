//@ts-nocheck
import { ColumnFilter } from "./_column-filter"
import { customLatvianSort } from "@Utility/utilils";


export const COLUMNS = [
    {
        Header: 'Pamatnosaukums',
        accessor: 'pamatnosaukums',
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    },
    {
        Header: 'Objekta ID',
        accessor: 'objektaid',
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    },
    {
        Header: 'Oficiālais nosaukums',
        accessor: 'oficNosaukums',
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    },
    {
        Header: 'Objekta veids',
        accessor: 'veidsVEIDS3',
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    },
    {
        Header: 'Objekta stāvoklis',
        accessor: 'stavoklisNavigationSTAVOKLIS',
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    },

    {
        Header: 'Administratīvā teritorija', 
        accessor: row => `${row.teritorijaPAGASTS}, ${row.teritorijaNOVADS}`, 
        Filter: ColumnFilter,
        /* sortType: customLatvianSort, */
    }


]