//@ts-nocheck
'use client';
import React, { useState, useEffect, useRef, Suspense } from "react";
import Table from "@Components/_table";
//import Table from '@Components/_table';

import CloseButton from "../../components/_close-button";
import { LuX } from 'react-icons/lu';
import { v4 as uuidv4 } from "uuid";
import { capitalizeFirstLetter } from "@Utility/utilils";
import { useEffectOnce } from "@Utility/customHook";
import TooltipWindow from '../../components/_tooltip-window';
import { LuHelpCircle } from 'react-icons/lu';

import { useGlobalContext } from "../Context/store";

import { useRouter } from 'next/navigation';
import { useSearchParams } from "next/navigation";

import '../../styles/style.css';

type TabType = {
    id: number;
    component: JSX.Element;
    title: string;
    type: string;
};

type TabsProps = {
    data: any; // avoid any
    tabs: any[]; // avoid any
    setTabs: React.Dispatch<React.SetStateAction<any[]>>;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
    onRowClick: Function;
    rowObjectID: number;
    //setRowObjectID: React.<SetStateAction<number>>;
    rowNosaukums: string;
    rowVeids: string;
    onObjectFetchAPI: Function;
    setObjectTabMap: Function;
    tabComponents: any; // avoid any
    onExportExcel: () => void;
    onActiveTab: (id: string) => void;
    onObjectViewCreate: () => void;
    onTabAdd: (tab: TabType) => void;
    onTabRemove: (tab: TabType) => void;
    tabsRef: any[];
    tabCollection: any[];
    onTabCollection: (newTabCollection: any[]) => void;
    filteredTabCollection: any;
    setRowObjectID: React.Dispatch<React.SetStateAction<number>>;
    searchValues: any;
    searchParameters: string[];

};



function Tabs() {
    //@ts-ignore

    const { data, tabs, activeTab, onActiveTab, onObjectViewCreate, onTabAdd, onTabRemove, tabsRef, tabCollection, onTabCollection, filteredTabCollection, onRowClick, rowObjectID, setRowObjectID, rowNosaukums, rowVeids, onObjectFetchAPI, setObjectTabMap, tabComponents, onExportExcel, searchValues, checkboxes, setSearchValues, initialLoad, setInitialLoad } = useGlobalContext();

    const searchParams = useSearchParams();
    //STATES


    const [tabRenderer, setTabRenderer] = useState<any>([]);
    // const [selectedURL, setSelectedURL] = useState<string>('')
    const router = useRouter();
    let selectedURL = '';



    useEffect(() => {
        if (data.data.length) {

            let objID: number = searchValues.objectID ? searchValues.objectID : 0;
            let regionaID: number = searchValues.objectCountry ? searchValues.objectCountry : 0;
            let rajonaID: number = searchValues.objectMunicipality ? searchValues.objectMunicipality : 0;
            let objVeids: string = encodeURIComponent(searchValues.objectType);
            let nosaukDala: string = encodeURIComponent(searchValues.objectName);
            let nosExact: boolean = checkboxes.exact ? checkboxes.exact : false;
            let citataDala: boolean = checkboxes.searchInSources ? checkboxes.searchInSources : false;
            let oficNos: boolean = checkboxes.officialNamesOnly ? checkboxes.officialNamesOnly : false;
            let nonAccentNos: boolean = checkboxes.ignoreDiacritics ? checkboxes.ignoreDiacritics : false;
            let LGIAapstipr: boolean = checkboxes.lgiaApprovedOnly ? checkboxes.lgiaApprovedOnly : false;

            /*             if (initialLoad && (!searchValues.objectID && !searchValues.objectName && !searchValues.objectType && !searchValues.objectCountry && !searchValues.objectMunicipality)) {
                            console.log("search values ir tukši, tpc update")
                            const updatedSearchValues = {
                                objectID: searchParams.get("id") ?? '',
                                objectName: searchParams.get("nosaukDala") ?? '',
                                objectType: searchParams.get("veidi") ?? '',
                                objectCountry: searchParams.get("regionaID") ?? 0,
                                objectMunicipality: searchParams.get("rajonaID") ?? 0,
                            };
            
            
            
                            let urlWithSearchParameters = `/search?id=${searchParams.get("id")}&regionaID=${searchParams.get("regionaID")}&rajonaID=${searchParams.get("rajonaID")}&veidi=${searchParams.get("veidi")}&nosaukDala=${searchParams.get("nosaukDala")}&nosExact=${nosExact}&citataDala=${citataDala}&nosOficial=${oficNos}&nosNonAccent=${nonAccentNos}&nosLGIAApproved=${LGIAapstipr}&sortInfo=1`;
            
            
                        }
            
                        else { */

            const updatedSearchValues = {
                objectID: searchParams.get("id") ? Number(searchParams.get("id")) || 0 : 0,
                objectName: searchParams.get("nosaukDala") ?? '',
                objectType: searchParams.get("veidi") ?? '',
                objectCountry: searchParams.get("regionaID") ?? 0,
                objectMunicipality: searchParams.get("rajonaID") ?? 0,
                nosExact: checkboxes.exact ? checkboxes.exact : false,
                citataDala: checkboxes.searchInSources ? checkboxes.searchInSources : false,
                oficNos: checkboxes.officialNamesOnly ? checkboxes.officialNamesOnly : false,
                nonAccentNos: checkboxes.ignoreDiacritics ? checkboxes.ignoreDiacritics : false,
                LGIAapstipr: checkboxes.lgiaApprovedOnly ? checkboxes.lgiaApprovedOnly : false,
            };




            let urlWithSearchParameters = `/search?id=${updatedSearchValues.objectID}&regionaID=${updatedSearchValues.objectCountry}&rajonaID=${updatedSearchValues.objectMunicipality}&veidi=${updatedSearchValues.objectType}&nosaukDala=${updatedSearchValues.objectName}&nosExact=${updatedSearchValues.nosExact}&citataDala=${updatedSearchValues.citataDala}&nosOficial=${updatedSearchValues.oficNos}&nosNonAccent=${updatedSearchValues.nonAccentNos}&nosLGIAApproved=${updatedSearchValues.LGIAapstipr}&sortInfo=1`;


            let urlWithoutSearchParameters = `/search?id=${objID}&regionaID=${regionaID}&rajonaID=${rajonaID}&veidi=${objVeids}&nosaukDala=${nosaukDala}&nosExact=${nosExact}&citataDala=${citataDala}&nosOficial=${oficNos}&nosNonAccent=${nonAccentNos}&nosLGIAApproved=${LGIAapstipr}&sortInfo=1`;

            console.log(initialLoad, searchValues.objectID, searchValues.objectName, searchValues.objectType, searchValues.objectCountry, searchValues.objectMunicipality)


            if (initialLoad && (!searchValues.objectID && !searchValues.objectName && !searchValues.objectType && !searchValues.objectCountry && !searchValues.objectMunicipality)) {
                selectedURL = urlWithSearchParameters;
                console.log("with search parameters ", selectedURL)
                router.push(urlWithSearchParameters, undefined, { shallow: true });
                setInitialLoad(false)

            }
            else {

                //console.log(iinitialLoad, searchValues.objectID)

                selectedURL = urlWithoutSearchParameters;
                console.log("without search parameters ", selectedURL)
                router.push(urlWithoutSearchParameters, undefined, { shallow: true });
                setInitialLoad(false)

            }
            //}




            const id = data.id;
            const tab = {
                id,
                //@ts-ignore
                component: <Table key={id} data={data.data} onRowClick={onObjectViewCreate} onExportExcel={onExportExcel} rowObjectID={rowObjectID} setRowObjectID={setRowObjectID} onObjectFetchAPI={onObjectFetchAPI} filteredTabCollection={filteredTabCollection} searchParameters={searchValues.objectName} />,
                title: searchValues.objectName
                    ? `Rezultāti: ${capitalizeFirstLetter(searchValues.objectName)}`
                    : searchValues.objectID
                        ? `Rezultāti: ID = ${searchValues.objectID}`
                        : 'Rezultāti',
                type: 'table',
                searchParameters: selectedURL
            }
            console.log(tab.searchParameters)
            onTabAdd(tab);
        }
    }, [data])



    useEffect(() => {
        console.log(tabs)
        const newTabCollection = tabs.map(tab => {
            let arr = [], visibleArr: { id: number; visible: boolean }[] = [];;
            if (tab.component.props) {
                if (tab.component.props.data) {
                    arr = tab.component.props.data.map((obj: any) => {
                        visibleArr.push({
                            id: obj.objektaid,
                            visible: true
                        })
                        return obj.objektaid;
                    })
                } else if (tab.component.props.objectId) {
                    arr = [tab.component.props.objectId]
                }
            }
            return {
                'tabId': tab.id,
                'items': arr,
                'visibleItems': visibleArr
            }
        })

        onTabCollection(newTabCollection)
    }, [tabs]);



    useEffect(() => {
        //@ts-ignore
        if (!searchValues) {

        }
        const filteredTabs = tabs.map(item => {
            let newData;
            if (item.type === 'table') {
                newData = filteredTabCollection.items && filteredTabCollection.items.length > 0 && item.id === filteredTabCollection.id ? filteredTabCollection.items.map((id: number) => {
                    const filteredData = item.component.props.data.find((prop: any) => prop.objektaid === id)
                    return filteredData
                }) : [...item.component.props.data]
            }

            return {
                id: item.id,
                searchParameters: Object.values(searchValues).join(', '),
                component: item.type === 'table' ? <Table key={item.id} data={newData} onRowClick={onObjectViewCreate} onExportExcel={onExportExcel} rowObjectID={rowObjectID} setRowObjectID={setRowObjectID} onObjectFetchAPI={onObjectFetchAPI} filteredTabCollection={filteredTabCollection} searchParameters={item.searchParameters} /> : item.component,
                title: item.title,
                type: item.type,

            }

        })

        setTabRenderer((tabRenderer: any) => [...filteredTabs])
    }, [tabs, filteredTabCollection])



    /*     const initialSearchValues = {
            objectType: '',
            objectID: '' || 0,
            objectName: '',
            objectCountry: 0,
            objectMunicipality: 0
        };
     
        const generateTooltipText = (searchValues) => {
            const labels = {
                objectType: 'Objekta veids',
                objectID: 'ID',
                objectName: 'Objekta nosaukums',
                objectCountry: 'Latviešu vēsturiskā zeme',
                objectMunicipality: 'Novads',
            };
     
            const changedValues = Object.keys(searchValues).filter(
                (key) => searchValues[key] !== initialSearchValues[key]
            );
     
            const tooltipText = changedValues
                .map((key) => `${labels[key]}: ${searchValues[key]}`)
                .join('; ');
     
            return `${tooltipText}`;
        }; */

    return (
        <div className="tabs-container">
            <Suspense fallback={<>Loading...</>}>
                <div className="bloc-tabs">

                    {tabs.map((tab: any) => (
                        <div
                            key={tab.id}
                            className={activeTab === tab.id ? "active-tabs" : "tabs"}
                            onClick={(event) => {
                                onActiveTab(tab.id);
                            }}


                        >
                            <button className={activeTab === tab.id ? "tabs-button active-tab-title" : "tabs-button"} >
                                {tab.title}
                            </button>
                            <CloseButton className="close-button" onButtonClick={(event) => { event.stopPropagation(); onTabRemove(tab.id) }} icon={<LuX />} title='' />
                            {activeTab === tab.id && <div className="active-tabs-bg" />}
                        </div>
                    ))}
                </div>



                <div className="content-tabs" >
                    {tabRenderer.map((tab: TabType) => {
                        return (
                            <div
                                key={tab.id}
                                className={activeTab === tab.id ? "tabs-content active-content" : "tabs-content"}
                            >

                                {tab.component}
                            </div>
                        )
                    })}
                </div>
            </Suspense>
        </div >
    );
}

export default Tabs;