//@ts-nocheck
'use client';
import React, { useState, useMemo, useEffect } from 'react'
import { useTable, usePagination, useSortBy, useResizeColumns, useFilters, TableInstance } from 'react-table';
import { COLUMNS } from './Columns.js';
import PageButton from './_page-button';
import { SiMicrosoftexcel } from 'react-icons/si';
import ExcelButton from './_excel-button';
import { LuX } from 'react-icons/lu';
import { LuPrinter } from 'react-icons/lu'



import { customLatvianSort } from "@Utility/utilils";



type MyTableInstance = TableInstance<any>;

type TableProps = {
    data: any[];
    onRowClick: Function;
    rowObjectID: number;
    setRowObjectID: Function;
    onObjectFetchAPI: Function;
    onExportExcel: () => void;
    filteredTabCollection: number[];
    searchParameters: any[];
}


function Table({ data, onRowClick, rowObjectID, setRowObjectID, onObjectFetchAPI, onExportExcel, filteredTabCollection, searchParameters }: TableProps) {
    const columns = useMemo(() => COLUMNS, []);
    const [pageSize, setPageSize] = useState(50);





    /*     const filteredData = useMemo(() => {
            return data.filter((item: any) => specificIDsToDisplay.includes(item.objektaid));
        }, [data]);
     */
    /*     const filteredData = data.forEach(item => {
            console.log(item)
        }) */

    /*     console.log(filteredTabCollection)
        console.log(data) */
    /*     const newData = filteredTabCollection.length > 0 ? filteredTabCollection.map(item => {
            const filteredData = data.find(obj => obj.objektaid === item)
            return filteredData
        }) : data
    
        console.log(newData) */

    const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, pageCount, gotoPage, canPreviousPage, canNextPage, prepareRow, state: { pageIndex }, setPageSize: setTablePageSize, tab } = useTable<MyTableInstance>(
        {
            columns,
            data,
            initialState: {
                sortBy: [{ id: 'defaultSortColumn' }],
                pageIndex: 0, pageSize // Set your default sorting column here
            },

        },
        useFilters,
        useSortBy,
        usePagination,
        useResizeColumns
    );

    useEffect(() => {
        setTablePageSize(pageSize); // Update the table's pageSize when local state changes
    }, [pageSize, setTablePageSize]);

    const getPageNumbers = () => {
        const currentPage = pageIndex + 1;
        const pageNumbers = [currentPage - 1, currentPage, currentPage + 1];
        return pageNumbers.filter((pageNumber) => pageNumber > 0 && pageNumber <= pageCount);
    };

    /*     useEffect(() => {
            console.log(page)
        }, [page]) */

    return (
        <>
            <div>
                <div className='table-stats' style={{ width: '100%' }}>
                    <div className="left-buttons">
                        <div className="number-of-objects">
                            Objektu skaits šķirklī &nbsp;{' '}
                            <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                <option value={10}> {' '}10{' '}</option>
                                <option value={25}> {' '}25{' '} </option>
                                <option value={50}> {' '}50{' '}</option>
                                <option value={100}> {' '}100{' '}</option>
                                <option value={250}> {' '}250{' '}</option>
                                <option value={500}> {' '}500{' '}</option>
                            </select>
                        </div>
                        {/*  <div className="page-buttons"> */}

                        <button className={`page-button ${!canPreviousPage ? 'disabled' : ''}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            &lt;&lt;
                        </button>

                        <button className={`page-button ${!canPreviousPage ? 'disabled' : ''}`} onClick={() => previousPage()} disabled={!canPreviousPage}>
                            &lt;
                        </button>

                        <button className={`page-button ${!canNextPage ? 'disabled' : ''}`} onClick={() => nextPage()} disabled={!canNextPage}>
                            &gt;
                        </button>

                        <button className={`page-button ${!canNextPage ? 'disabled' : ''}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            &gt;&gt;
                        </button>


                        &nbsp; Lpp. {pageIndex + 1}/{pageCount}, kopējais elementu skaits {data.length}
                    </div>
                    <div className='right-buttons'>
                        <ExcelButton className="excel-button" title=".xlsx" onButtonClick={() => onExportExcel(searchParameters)} icon={<SiMicrosoftexcel />}>
                        </ExcelButton>
                        <ExcelButton className="excel-button" title="Drukāt" onButtonClick={() => { window.print() }} icon={<LuPrinter />}>
                        </ExcelButton>
                    </div>


                </div>
            </div >
            <div className='scroll-table'>
                <table className="custom-table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column: any) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())} // Enable sorting for the column header
                                        style={{ width: column.width }}
                                        key={column.id}
                                        /* sortType={customLatvianSort} */
                                        title="Kārtot pēc alfabēta"
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' (Z-A)'
                                                    : ' (A-Z)'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            const rowObjectID = row.original?.objektaid;
                            const rowNosaukums = row.original?.pamatnosaukums;
                            const rowVeids = row.original?.veidsVEIDS3;

                            return (
                                <tr key={row.id} {...row.getRowProps()}
                                    onClick={() => {
                                        const selection = window.getSelection();
                                        if (selection && selection.toString() === '') {
                                            onRowClick(rowObjectID, rowNosaukums, rowVeids);
                                        }
                                    }}
                                >
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()} key={cell.id}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table >
            </div>
        </>
    );
}


export default Table;