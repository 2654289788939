import { COLUMNS } from './Columns'
import React, { useState, useMemo } from 'react';
import { Column } from 'react-table';

type ColumnFilterProps = {
    column: any; // avoid any. should be Column, from 'react-table' but it does not have filterValue, setFilter properties
}


export const ColumnFilter = ({ column }: ColumnFilterProps) => {
    const { filterValue, setFilter } = column
    return (
        <span>
            <input value={filterValue || ''} type="text" id="veids" name='veids' onChange={(e) => setFilter(e.target.value)} />
        </span>
    )
}