import React from 'react';

type ExcelButtonProps = {
    icon?: any;
    id?: string;
    className?: string;
    onButtonClick?: () => void;
    toggle?: boolean;
    active?: boolean;
    title?: string;
};

export default function ExcelButton({ icon = '\u00A0', id = '', className = '', onButtonClick, toggle = false, active = false, title = '' }: ExcelButtonProps) {



    if (toggle) {
        return (
            <div className={`excel-button excel-button__toggle ${className}`} title={title} id={id} onClick={onButtonClick}>
                <input type='checkbox' className='excel-button__checkbox' id={id + 'Checkbox'} checked={active} readOnly />
                <label htmlFor={id + 'Checkbox'} className='excel-button__button' >
                    <span className="excel-button__icon">
                        {icon}
                    </span>
                </label>
            </div>
        )
    }

    return (
        <button className={`excel-button ${className}`} title={title} onClick={onButtonClick}>
            <span className="excel-button__icon">
                {icon}
            </span>
        </button>
    )
}